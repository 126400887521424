// @flow
import React from 'react';

const Home = () => (
  <div className="content-wrapper">
    <div className="section group">
      <h1>Anthony Nolan forms</h1>
      <ul>
        <li>
          <a href="/donor-application">Join our stem cell register</a>
        </li>
        <li>
          <a href="/one-off-payment">One off gift</a>
        </li>
        <li>
          <a href="/update-your-details">Update your details</a>
        </li>
        <li>
          <a href="/donate-your-umbilical-cord">Donate your umbilical cord</a>
        </li>
        <li>
          <a href="/volunteer-ambassador">Volunteer details</a>
        </li>
        <li>
          <a href="/duke-edinburgh-leader">Leader details</a>
        </li>
      </ul>
    </div>
  </div>
);

export default Home;
