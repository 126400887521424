// @flow
/* eslint-disable import/first */
import './config/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { configureFormComponents, FormScreen } from '@an/nova-form-components';
import config from './config';
import configureStore from './configureStore';
import Home from './core/components/Home';
import Header from './core/components/Header';
import Footer from './core/components/Footer';
import NotFound from './core/components/NotFound';
import { unregister } from './registerServiceWorker';
import './assets/styles/App.scss';

const store = configureStore();
configureFormComponents(
  config.apiBaseUrl,
  config.stripePublishableKey,
  config.googleReCaptchaKey,
  config.sampleIdValidateUrl,
  config.sampleIdPostUrl
);

const App = () => (
  <div>
    <Header />
    <main role="main" className="content-wrapper">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/:id" component={FormScreen} />
        <Route component={NotFound} />
      </Switch>
    </main>
    <Footer />
  </div>
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  // $FlowExpectedError
  document.getElementById('root')
);
unregister();
