// @flow
import React from 'react';

const NotFound = () => (
  <div className="content-wrapper">
    <div className="section group">
      <h2>Page not found</h2>
      <h3>We might be experts in finding matches, but we couldn&apos;t find the page you were looking for.</h3>
      <p>
        How to join our{' '}
        <a
          href="https://www.anthonynolan.org/8-ways-you-could-save-life/donate-your-stem-cells/apply-join-our-register"
          target="_blank"
          rel="noopener noreferrer"
        >
          stem cell and bone marrow register
        </a>
        .
      </p>
      <p>
        Find out how to get involved in our{' '}
        <a
          href="https://www.anthonynolan.org/8-ways-you-could-save-life/take-part-fundraising-event-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          fundraising events
        </a>
        .
      </p>
      <p>
        Find information for{' '}
        <a href="https://www.anthonynolan.org/clinicians-and-researchers" target="_blank" rel="noopener noreferrer">
          clinicians and researchers
        </a>
        .
      </p>
      <p>
        Find information for{' '}
        <a href="https://www.anthonynolan.org/patients-and-families" target="_blank" rel="noopener noreferrer">
          patients and families
        </a>
        .
      </p>
      <p>
        Learn about other ways{' '}
        <a href="https://www.anthonynolan.org/8-ways" target="_blank" rel="noopener noreferrer">
          you can support our lifesaving work
        </a>
        .
      </p>
    </div>
  </div>
);

export default NotFound;
