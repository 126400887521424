/* eslint-disable import/no-import-module-exports */
// @flow
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunkMiddleware from 'redux-thunk';
import immutableCheckMiddleWare from 'redux-immutable-state-invariant';
import { createLogger } from 'redux-logger';
import rootReducer from './rootReducer';

const loggerMiddleware = createLogger({
  predicate: (getState, action) =>
    action.type !== '@@redux-form/REGISTER_FIELD' &&
    action.type !== '@@redux-form/FOCUS' &&
    action.type !== '@@redux-form/CHANGE' &&
    action.type !== '@@redux-form/BLUR' &&
    action.type !== '@@redux-form/UPDATE_SYNC_ERRORS' &&
    action.type !== '@@redux-form/UNREGISTER_FIELD',
});

const analytics = () => (next) => (action) => {
  const dataLayer = window.dataLayer || [];
  if (action.type === 'analytics/event') {
    if (action.eventType === 'VirtualPageView') {
      dataLayer.push({
        event: 'VirtualPageview',
        virtualPageURL: action.event.virtualPageURL,
        virtualPageTitle: action.event.virtualPageTitle,
      });
    }
  }
  return next(action);
};

const middleware = [];
middleware.push(thunkMiddleware);
middleware.push(analytics);

if (process.env.NODE_ENV === 'development') {
  middleware.push(immutableCheckMiddleWare());
  middleware.push(loggerMiddleware);
}

export default function configureStore(preloadedState?: Object) {
  const store = createStore<*, *, *>(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(...middleware)));

  // $FlowExpectedError
  if (module.hot) {
    // $FlowExpectedError
    module.hot.accept('./rootReducer', () => {
      // eslint-disable-next-line global-require
      const nextReducer = require('./rootReducer').default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
