// @flow
import React from 'react';

const Footer = () => (
  <footer>
    <div className="section">
      <ul>
        <li>
          <a href="https://www.anthonynolan.org/data-protection">Data protection</a>
        </li>
        <li>
          <a href="https://www.anthonynolan.org/cookies-policy">Cookies policy</a>
        </li>
        <li>
          <a href="https://www.anthonynolan.org/modern-slavery-statement">Modern slavery statement</a>
        </li>
        <li>
          <a href="https://www.anthonynolan.org/privacy-policy">Privacy policy</a>
        </li>
        <li>
          <a href="https://www.anthonynolan.org/terms-and-conditions">Terms and conditions</a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
