// @flow
// eslint-disable-next-line no-underscore-dangle
const env = window._env_;
export default {
  apiBaseUrl: env.REACT_APP_API_BASE || 'https://dev-nova-website-api.azurewebsites.net/api',
  donorApplicationFormUuid: 'aab1535c-4861-433a-8abb-ec0859392cd2',
  stripePublishableKey: env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
  googleReCaptchaKey: env.REACT_APP_GOOGLE_RECAPTCHA_KEY || '6LelIxQiAAAAABo0EcFBFiqIV-Cdw2tYPslUmPJn',
  sampleIdValidateUrl:
    env.REACT_APP_SAMPLE_ID_VALIDATE_URL ||
    'https://prod-22.uksouth.logic.azure.com/workflows/275250cabfe14152a4697d041ba7528e/triggers/manual/paths/invoke/sampleId/{sampleId}?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=lwLpRSXXS8fvdnDoAiJyreq8uCyjPxU_YjaUi2Wl_GA',
  sampleIdPostUrl:
    env.REACT_APP_SAMPLE_ID_POST_URL ||
    'https://prod-31.uksouth.logic.azure.com/workflows/d4acd0c54d4f4398b25b42318a461e30/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=6aA3xOFkup19NYLch4NC769qbNxykS3R5ixo28sTyu0',
};
