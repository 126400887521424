// @flow
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import queryString from 'query-string';
import logo from '../../assets/images/an-logo.svg';

type DonorApplicationQueryParams = {
  ev: string,
  en: string,
  sm: string,
};

const donorApplicationHeaderText = (queryParams?: DonorApplicationQueryParams) => {
  if (queryParams) {
    const { en: eventName, ev: eventId, sm: sampleId } = queryParams;
    return eventName && eventId && sampleId && `${atob(eventName)} (${atob(eventId)}) | ${atob(sampleId)}`;
  }
  return null;
};

const Header = () => (
  <header>
    <div className="wrapper grid">
      <div className="g-col-4">
        <a href="https://www.anthonynolan.org/">
          <img src={logo} alt="Anthony Nolan | Saving lives through stem cells" />
        </a>
      </div>
      <div className="g-col-7 g-start-6">
        <Switch>
          <Route exact path="/bone-marrow-stem-cell-register">
            <h1>Apply to join the stem cell register</h1>
          </Route>
          <Route
            path="/donor-application"
            render={({ location }) => (
              <h1>
                Apply to join the stem cell register {donorApplicationHeaderText(queryString.parse(location.search))}
              </h1>
            )}
          />
          <Route path="/keep-in-touch">
            <h1>Update your preferences</h1>
          </Route>
          <Route path="/update-your-details">
            <h1>Update your details</h1>
          </Route>
          <Route path="/one-off-payment">
            <h1>Donate Now</h1>
          </Route>
          <Route path="/donate-your-umbilical-cord">
            <h1>Donate your umbilical&nbsp;cord</h1>
          </Route>
          <Route path="/volunteer-ambassador">
            <h1>Volunteer details</h1>
          </Route>
          <Route path="/duke-edinburgh-leader">
            <h1>Leader details</h1>
          </Route>
        </Switch>
      </div>
    </div>
  </header>
);

export default Header;
